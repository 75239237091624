import styled from 'styled-components'
import { Col, Row } from 'antd'
import ExternalLinkIcon from '../../Icon/ExternalLinkIcon'

const items = [
    {
        title: 'Trading Volume',
        content: '$1,321,262,376',
        description: 'exchanged in the last 24h'
    },
    {
        title: 'Open Interest',
        content: '$301,579,709',
        description: 'in open positions on dYdX'
    },
    {
        title: 'Trades',
        content: '551,399',
        description: 'executed in the last 24h'
    },
]

const StatisticBox = () => {

    return (
        <Wrapper>

            <Row gutter={[36, 12]}>
                {
                    items.map((item, index) => (
                        <StyledCol key={index} xs={24} md={12} lg={8}>
                            <BoxWrapper>
                                <Title>
                                    {item.title}
                                </Title>
                                <Content className='content-value'>
                                    {item.content}
                                </Content>
                                <Description>
                                    {item.description}
                                </Description>
                            </BoxWrapper>
                        </StyledCol>
                    ))
                }


            </Row>

        </Wrapper>
    )
}

export default StatisticBox

const Wrapper = styled.div`
`

const BoxWrapper = styled.div`
  
`

const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  color: #6F6E84;
  margin-bottom: 16px;
`

const Content = styled.div`
  font-weight: 500;
  font-size: 24px;
`

const Description = styled.div`
  font-weight: 500;
  font-size: 13px;
  color: #6F6E84;
`

const StyledCol = styled(Col)`
  border-right: 1px solid #6F6E84;

  &:last-child {
    border-right: none;
  }
`