import Icon from '@ant-design/icons'

const OverviewIcon = (props) => {
    const { stroke = '#F7F7F7', color = '#2D2D3B' } = props

    return (
        <Icon className='anticon' viewBox='0 0 12 12'>
            <g clipPath="url(#clip0_84_1091)">
                <path d="M6.69997 7.26601C6.69997 5.55393 5.31206 4.16602 3.59998 4.16602C1.88791 4.16602 0.5 5.55393 0.5 7.26601V7.39938C0.5 9.11145 1.88791 10.4994 3.59998 10.4994C5.31206 10.4994 6.69997 9.11145 6.69997 7.39938V7.26601Z" fill={color} stroke={stroke} />
                <path d="M11.4998 7.26601C11.4998 5.55393 10.1119 4.16602 8.39979 4.16602C6.68772 4.16602 5.2998 5.55393 5.2998 7.26601V7.39938C5.2998 9.11145 6.68772 10.4994 8.39979 10.4994C10.1119 10.4994 11.4998 9.11145 11.4998 7.39938V7.26601Z" fill={color} stroke={stroke} />
                <path d="M5.9994 0.5H5.99939C4.28732 0.5 2.89941 1.88791 2.89941 3.59998V3.73337C2.89941 5.44544 4.28732 6.83335 5.99939 6.83335H5.9994C7.71148 6.83335 9.09938 5.44544 9.09938 3.73337V3.59998C9.09938 1.88791 7.71148 0.5 5.9994 0.5Z" fill={color} stroke={stroke} />
            </g>
            <defs>
                <clipPath id="clip0_84_1091">
                    <rect width="12" height="12" fill="white"/>
                </clipPath>
            </defs>
        </Icon>
    )
}

export default OverviewIcon