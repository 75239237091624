import Icon from '@ant-design/icons'

const ExternalLinkIcon = (props) => {
    const { color = '#6F6E84' } = props

    return (
        <Icon className='anticon' viewBox='0 0 14 12'>
            <path
                clipRule='evenodd'
                d='M6.80221 7.13807L11.6787 2.27614V5.33333H13.016V0H7.66671V1.33333H10.733L5.85659 6.19526L6.80221 7.13807ZM11.6806 10.6667V7.33333H10.3433V10.6667H2.3194V2.66667H5.66268V1.33333H2.3194C1.58082 1.33333 0.982086 1.93029 0.982086 2.66667V10.6667C0.982086 11.403 1.58082 12 2.3194 12H10.3433C11.0819 12 11.6806 11.403 11.6806 10.6667Z'
                fill={color}
                fillRule='evenodd'
            />
        </Icon>
    )
}

export default ExternalLinkIcon