import Icon from '@ant-design/icons'

const OrdersIcon = (props) => {
    const { color = '#6F6E84', stroke = '#6F6E84' } = props

    return (
        <Icon className='anticon' viewBox='0 0 17 16'>
            <g clipPath="url(#clip0_84_1105)">
                <path fill='transparent' d="M8.89941 15.5C13.0415 15.5 16.3994 12.1421 16.3994 8C16.3994 3.85786 13.0415 0.5 8.89941 0.5C4.75728 0.5 1.39941 3.85786 1.39941 8C1.39941 12.1421 4.75728 15.5 8.89941 15.5Z" stroke={stroke} strokeDasharray="3 3"/>
            </g>
            <defs>
                <clipPath id="clip0_84_1105">
                    <rect width="16" height="16" fill="transparent" transform="translate(0.899414)"/>
                </clipPath>
            </defs>
        </Icon>
    )
}

export default OrdersIcon