import styled from "styled-components";

export const Wrapper = styled.div`
`

export const Title = styled.div`
  font-size: 14px;
  margin-bottom: 6px;
  color: #B7BDC6;
`

export const Value = styled.div`
  font-size: 18px;
  font-weight: bold;
`

export const SubValue = styled.span`
  font-size: 16px;
  color: rgb(183, 189, 198);
  margin-left: 4px;
`

export const ValueWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const RowWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`

export const RewardValue = styled(Value)`
    color: #3FB68B;
`

export const ModalTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 16px;
`

export const DetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

export const Detail = styled.div`
  font-size: 16px;
  color: #B7BDC6;
`