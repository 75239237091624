import BigNumber from 'bignumber.js';

export const numberWithCommas = (value, toFixed = null, millonCollect = true) => {
  if (isNaN(value) || !value) return 0;
  let numDec = new BigNumber('' + value);
  if (millonCollect) {
    if (numDec.toNumber() > 1000000)
      return numDec.div(1000000).toFormat(2) + 'M';
  }
  return numDec.toFormat(toFixed);
};

const DATE = 'YYYY-MM-DD';
const TIME = 'HH:mm:ss';
const DATE_TIME = `${DATE} ${TIME}`;

export { DATE, TIME, DATE_TIME };
