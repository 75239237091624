import { Breadcrumb, Col, Layout, Menu, Row } from 'antd'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import OverviewIcon from '../../components/Icon/OverviewIcon'
import { useSelector } from 'react-redux'
import PositionsIcon from '../../components/Icon/PositionsIcon'
import OrdersIcon from '../../components/Icon/OrdersIcon'
import FeeIcons from '../../components/Icon/FeeIcons'
import HistoryIcon from '../../components/Icon/HistoryIcon'
import InformationBox from '../../components/Portfolio/InformationBox/InformationBox'
import Markets from '../../components/Portfolio/Markets/Markets'
import PortfolioValue from '../../components/Portfolio/PortfolioValue/PortfolioValue'
import useWindowSize from '../../customHooks/useWindowSize'
import { useNavigate } from 'react-router-dom'
import { RiseOutlined } from '@ant-design/icons'
import { themeSelector } from '../../modules/common/selector'

const { Sider } = Layout;

const Index = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [selected, setSelected] = useState('portfolio')
    const [windowSize] = useWindowSize();
    const navigate = useNavigate();

    const theme = useSelector(themeSelector);

    useEffect(() => {
        if (windowSize?.width > 992) {
            setCollapsed(false)
        } else {
            setCollapsed(true)
        }
    }, [windowSize?.width])

    const supportedPaths = ['earn', 'portfolio']

    function getItem(label, key, icon, children) {
        return {
            onClick: () => supportedPaths.includes(key) ? navigate(`/${key}`) : null,
            key,
            icon,
            children,
            label,
        };
    }

    const items = [
        getItem('Overview', 'portfolio', <IconWrapper className='sider-background'><OverviewIcon color={theme === 'dark' ? '#303044' : '#0064FF'} stroke={selected === 'portfolio' ? '#F7F7F7' : '#6F6E84'} /></IconWrapper>),
        getItem('Earn', 'earn', <IconWrapper className='sider-background'><RiseOutlined color={theme !== 'dark' && '#A6C9FF'} /></IconWrapper>),
        getItem('Positions', 'positions', <IconWrapper className='sider-background'><PositionsIcon color={theme !== 'dark' && '#A6C9FF'} /></IconWrapper>),
        getItem('Orders', 'orders', <IconWrapper className='sider-background'><OrdersIcon stroke={theme === 'dark' ? '#6F6E84' : '#A6C9FF'} /></IconWrapper>),
        getItem('Fees', 'fees', <IconWrapper className='sider-background'><FeeIcons color={theme !== 'dark' && '#A6C9FF'} /></IconWrapper>),
        getItem('History', 'history', <IconWrapper className='sider-background'><HistoryIcon color={theme !== 'dark' && '#A6C9FF'} /></IconWrapper>),
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider width={300} collapsible={windowSize?.width > 992 ? true : false} collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <Wrapper>
                    <HeaderWrapper>
                        <div
                        >
                            VIEWS
                        </div>

                        {
                            !collapsed && (
                                <div className='hide-badge-wrapper' onClick={() => setCollapsed(!collapsed)}>
                                    HIDE
                                </div>
                            )
                        }

                    </HeaderWrapper>

                    <Menu selectedKeys={['portfolio']} theme={theme} mode="inline" items={items} />

                </Wrapper>
            </Sider>
            <Layout className="site-layout">
                <Wrapper>
                    {/* Portfolio value */}
                    <PortfolioValue />

                    <Spacer />

                    {/* Information */}
                    <SectionWrapper>
                        <InformationBox />
                    </SectionWrapper>

                    <Spacer />

                    {/* Markets */}
                    <SectionWrapper>
                        <Markets />
                    </SectionWrapper>

                </Wrapper>
            </Layout>
        </Layout>
    )
}

export default Index

const Wrapper = styled.div`
`

const SectionWrapper = styled.div`
  padding: 16px;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Spacer = styled.div`
  height: 56px;
`