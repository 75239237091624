import styled from 'styled-components'
import StatisticBox from '../StatisticBox/StatisticBox'
import { Avatar, Button, Modal, Space, Table } from 'antd'
import _network from '../../../mock/portfolio.json'
import { FORMAT } from '../../../helper'
import {
    SearchOutlined
} from '@ant-design/icons';
import { useState } from 'react'
import WithdrawTokenModal from '../../WithdrawTokenModal'

const Markets = () => {
    const [visibleWithdrawModal, setVisibleWithdrawModal] = useState(false)
    const [selectedToken, setSelectedToken] = useState(null)

    const columns = [
        {
            title: 'Market',
            dataIndex: 'token',
            key: 'market',
            render: (text) => (
                <Space>
                    <Avatar
                        src={`https://cdn.arken.finance/token/aurora/${text?.address}.png`}
                    />

                    <Space direction='vertical' size={0}>
                        <TokenText className='market-token-text'>{text?.symbol}</TokenText>
                        <NameText>
                            {text?.name}
                        </NameText>
                    </Space>
                </Space>
            )
        },
        {
            title: 'Metamask Balance',
            dataIndex: 'metamaskBalance',
            key: 'metamaskBalance',
            align: 'center',
            render: (text) => <span>{FORMAT.numberWithCommas(text, 2)}</span>,
        },
        {
            title: 'Staking',
            dataIndex: 'staking',
            key: 'staking',
            align: 'right',
            render: (text) => <span>{FORMAT.numberWithCommas(text, 2)}</span>,
        },
        {
            title: 'Fee Reward',
            dataIndex: 'reward',
            key: 'reward',
            align: 'right',
            render: (text) => <RewardWrapper>{FORMAT.numberWithCommas(text, 2)}</RewardWrapper>,
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (_, value) => (
                <ActionWrapper>
                    <Button type='link'>
                        Deposit
                    </Button>
                    <Button onClick={() => {
                        setSelectedToken(value)
                        setVisibleWithdrawModal(true)
                    }} type='link'>
                        Withdraw
                    </Button>
                </ActionWrapper>
            )
        }
    ]

    const handleCloseWithdrawModal = () => {
        setVisibleWithdrawModal(false)
        setSelectedToken(null)
    }

    return (
        <div>
            <MarketHeaderWrapper>
                <MarketTitle className='market-title'>
                    Markets
                </MarketTitle>

                <BadgeWrapper>
                    <Badge className='badge-active'>
                        All
                    </Badge>

                    <Badge className='badge-inactive'>
                        Layer 1
                    </Badge>

                    <Badge className='badge-inactive'>
                        Defi
                    </Badge>

                    <DividerHorizontal />

                    <SearchOutlined />
                </BadgeWrapper>
            </MarketHeaderWrapper>

            <Description>
                Discover new assets available to trade on dYdX
            </Description>

            <Spacer />

            <StatisticBox />

            <Spacer />

            {/* Asset Table */}
            <Table
                columns={columns}
                dataSource={_network.slice(0, 10)}
                pagination={false}
                scroll={{ x: true }}
            />

            {
                visibleWithdrawModal && (
                    <WithdrawTokenModal
                        visible={visibleWithdrawModal}
                        handleClose={handleCloseWithdrawModal}
                        token={selectedToken}
                    />
                )
            }


        </div>
    )
}

export default Markets

const MarketHeaderWrapper = styled.div`
    display: flex;
  justify-content: space-between;
  align-items: center;
`

const MarketTitle = styled.div`
  font-weight: 500;
  font-size: 19px;
  color: #F7F7F7;
`

const BadgeWrapper = styled.div`
    display: flex;
  align-items: center;
`


const Description = styled.div`
  font-weight: 500;
  font-size: 13px;
  color: #6F6E84;
`

const Spacer = styled.div`
  height: 36px;
`

const TokenText = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #C3C2D4;
`

const NameText = styled.div`
  font-weight: 400;
  font-size: 11px;
  color: #6F6E84;
`

const Badge = styled.div`
  padding: 6px 10px;
  border-radius: 24px;
  margin-left: 8px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  background: ${props => props?.active ? '#171722' : '#303044'};
`

const DividerHorizontal = styled.div`
  width: 1px;
  height: 30px;
  background: #6F6E84;
  margin: 0 16px;
`

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const RewardWrapper = styled.span`
  color: #3FB68B;
  font-weight: bold;
`