import React, { useState } from 'react';
import useWindowSize from '../../customHooks/useWindowSize';
// import _network from '../../mock/network.json';

import ExchangeInfo from '../../components/ExchangeInfo';
import OrderBook from '../../components/OrderBook';
import MarketTableSM from '../../components/MarketTableSM';
import TradeHistory from '../../components/TradeHistory';
import OrderForm from '../../components/OrderForm';
import Chart from '../../components/Chart';
import { Row, Col, Tabs, Button, Drawer } from 'antd';

const { TabPane } = Tabs;

const Index = () => {
  const [windowSize] = useWindowSize();
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  return (
    <>
      {/* Desktop  */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '300px 1fr 300px',
          gridTemplateRows: '100px 300px 300px 200px',
          gridGap: 8,
        }}
        className='p-2 hidden lg:grid'
      >
        <div style={{ gridArea: '1 / 1 / 2 / 3' }}>
          <ExchangeInfo />
        </div>
        <div style={{ gridArea: '2 / 1 / 6 / 2' }}>
          <OrderBook />
        </div>
        <div style={{ gridArea: '2 / 2 / 4 / 3' }}>
          <Chart />
        </div>
        <div style={{ gridArea: '4 / 2 / 6 / 3' }}>
          <OrderForm />
        </div>
        <div style={{ gridArea: '1 / 3 / 4 / 3' }}>
          <MarketTableSM />
        </div>
        <div style={{ gridArea: '4 / 3 / 6 / 4' }}>
          <TradeHistory />
        </div>
      </div>

      <div className='flex flex-col p-2 gap-2  md:pb-2 pb-[70px] lg:hidden relative safe-area-padding-bottom'>
        {windowSize.width < 1024 && <ExchangeInfo />}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: windowSize.width > 768 ? '2fr 1fr' : '1fr',
            gridGap: 8,
          }}
          className='grid '
        >
          <Tabs>
            <TabPane key='chart' tab='Chart'>
              <Chart />
            </TabPane>
            <TabPane key='orderbook' tab='Order Book'>
              <OrderBook />
            </TabPane>
            <TabPane key='markethistory' tab='Market History'>
              <TradeHistory title={false} />
            </TabPane>
          </Tabs>
          <div className='md:block hidden sticky top-12'>
            <OrderForm />
          </div>
        </div>
      </div>
      <Drawer
        title='Exchange'
        placement='bottom'
        visible={visibleDrawer}
        contentWrapperStyle={{ height: 'auto' }}
        bodyStyle={{ padding: 8 }}
        onClose={() => setVisibleDrawer(false)}
      >
        <OrderForm title={false} />
      </Drawer>

      {/* buttom from action */}
      <div className='bg-white md:hidden block dark:bg-[#1a2230] rounded-none fixed bottom-0 w-screen px-2 pt-3 pb-4 border-solid dark:border-[#303030] border-[#f0f0f0] border-t-[1px] border-b-0 border-x-0'>
        <Row gutter={8} className='safe-area-padding-bottom'>
          <Col span={12}>
            <Button block type='bids' onClick={() => setVisibleDrawer(true)}>
              Buy
            </Button>
          </Col>
          <Col span={12}>
            <Button block type='asks' onClick={() => setVisibleDrawer(true)}>
              Sell
            </Button>
          </Col>
        </Row>
      </div>
      {/* buttom from action */}
    </>
  );
};

export default Index;
