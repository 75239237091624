import { Avatar, Button, Col, Input, Modal, Row } from 'antd'
import {
    Detail,
    DetailWrapper,
    ModalTitle,
    RewardValue,
    RowWrapper,
    SubValue,
    Title,
    Value,
    ValueWrapper,
    Wrapper
} from './WithdrawTokenModal.styles'

const WithdrawTokenModal = (props) => {
    const {
        visible,
        token,
        handleClose
    } = props

    return (
        <Modal onCancel={handleClose} centered footer={false} visible={visible}>
            <Wrapper>
                <ModalTitle>
                    Withdraw
                </ModalTitle>
                <RowWrapper>
                    <Title>
                        Token
                    </Title>

                    <ValueWrapper>
                        <Avatar
                            src={`https://cdn.arken.finance/token/aurora/${token?.token?.address}.png`}
                        />
                        <Value style={{ marginLeft: '6px' }}>
                            {token?.token?.symbol}
                        </Value>
                        <SubValue>{token?.token?.name}</SubValue>
                    </ValueWrapper>
                </RowWrapper>

                <Row>
                    <Col xs={24} md={12}>
                        <RowWrapper>
                            <Title>
                                Balance
                            </Title>

                            <Value>
                                {token?.staking} {token?.token?.symbol}
                            </Value>
                        </RowWrapper>
                    </Col>

                    <Col xs={24} md={12}>
                        <RowWrapper>
                            <Title>
                                Rewards
                            </Title>

                            <RewardValue>
                                {token?.reward} {token?.token?.symbol}
                            </RewardValue>
                        </RowWrapper>
                    </Col>
                </Row>

                <RowWrapper>
                    <Title>
                        Address
                    </Title>

                    <Input.TextArea placeholder='Wallet Address' autoSize={{ minRows: 2 }} size='large' />
                </RowWrapper>

                <RowWrapper>
                    <Title>
                        Amount
                    </Title>

                    <Input suffix={token?.token?.symbol} placeholder='Amount' size='large' />
                </RowWrapper>

                <DetailWrapper>
                    <Detail>
                        Reward Claimed
                    </Detail>

                    <Detail>
                       0.3 {token?.token?.symbol}
                    </Detail>
                </DetailWrapper>

                <DetailWrapper>
                    <Detail>
                        Destination will receive
                    </Detail>

                    <Detail>
                        {token?.staking} {token?.token?.symbol}
                    </Detail>
                </DetailWrapper>

                <br />

                <Button type='primary' block size='large'>
                    Withdraw
                </Button>
            </Wrapper>
        </Modal>
    )
}

export default WithdrawTokenModal