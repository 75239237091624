import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import useWindowSize from '../customHooks/useWindowSize';

import { Layout } from 'antd';
import AppHeader from '../components/AppHeader';
import AppFooter from '../components/AppFooter';

const { Content } = Layout;

const Index = () => {
  const location = useLocation();
  const [windowSize] = useWindowSize();

  return (
    <Layout>
      <AppHeader />
      <Content
        style={{
          minHeight: `calc(100vh - ${
            location?.pathname === '/trade' ? 64 : 134
          }px)`,
        }}
      >
        <Outlet />
      </Content>
      {/*{(windowSize.width > 1024 || !location?.pathname === '/trade') && (*/}
      {/*  <AppFooter />*/}
      {/*)}*/}
    </Layout>
  );
};

export default Index;
