import Icon from '@ant-design/icons'

const TransferIcon = (props) => {
    const { color = '#C3C2D4' } = props

    return (
        <Icon style={{ fontSize: '18px' }} className='anticon' viewBox='0 0 22 22'>
            <g clipPath="url(#clip0_84_1175)">
                <path d="M0.986584 9.73939H21.0135C21.4123 9.73939 21.772 9.47245 21.9249 9.0633C22.0776 8.65374 21.9931 8.18242 21.7107 7.86926L16.3303 1.89318C15.945 1.46529 15.3209 1.46529 14.9353 1.89318C14.5497 2.32107 14.55 3.01429 14.9353 3.44258L18.632 7.54855H0.986584C0.441721 7.54855 0.000163395 8.03899 0.000163395 8.64417C0.000163395 9.24934 0.441721 9.73939 0.986584 9.73939ZM21.0135 12.2621H0.986584C0.587711 12.2621 0.227937 12.529 0.0751312 12.9382C-0.0776742 13.3478 0.00697856 13.8191 0.289273 14.1322L5.66975 20.1084C5.86237 20.3222 6.11454 20.4294 6.36705 20.4294C6.61958 20.4294 6.87175 20.3222 7.06436 20.1084C7.44961 19.6805 7.44961 18.9872 7.06436 18.5589L3.36798 14.4533H21.0135C21.5583 14.4533 21.9998 13.9629 21.9998 13.3577C21.9998 12.7525 21.5583 12.2621 21.0135 12.2621Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_84_1175">
                    <rect width="22" height="22" fill="white"/>
                </clipPath>
            </defs>
        </Icon>
    )
}

export default TransferIcon