import { useEffect } from 'react';
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useThemeSwitcher } from 'react-css-theme-switcher';

import Layout from './layout';
import './App.css';
import 'antd/dist/antd.less';

import Market from './views/market';
import Trade from './views/trade';
import Page404 from './views/pages/404';
import Portfolio from './views/portfolio'
import Earn from './views/earn'
import { langSelector, themeSelector } from './modules/common/selector';
import { changeLanguageTask, changeThemeTask } from './modules/common/tasks';

function App() {
  const [, i18n] = useTranslation();
  const lang = useSelector(langSelector);
  const theme = useSelector(themeSelector);

  const { switcher, themes } = useThemeSwitcher();

  const { onRequest : changeTheme } = changeThemeTask.useTask()
  const { onRequest : changeLanguage } = changeLanguageTask.useTask()

  /* Setting the initial data for the app. */
  useEffect(() => {
    const initailData = () => {
      let lang = localStorage.getItem('lang') || 'en';
      let theme = localStorage.getItem('theme') || 'dark';

      i18n.changeLanguage(lang);
      changeTheme(theme);
      changeLanguage(lang);
    };

    initailData();
  }, [i18n]);

  /* Changing the language of the app. */
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  /* Setting the theme of the app. */
  useEffect(() => {
    switcher({ theme: theme === 'dark' ? themes.dark : themes.light });
  }, [theme, themes, switcher]);

  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Navigate to='market' />} />
            <Route path='market' element={<Market />} />
            <Route path='trade' element={<Trade />} />
            <Route path='trade/:chain/:address' element={<Trade />} />
            <Route path='portfolio' element={<Portfolio />} />
            <Route path='earn' element={<Earn />} />
          </Route>
          <Route path='*' element={<Page404 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
