import React from 'react';
import { FORMAT } from '../../helper';

import { Card, Typography } from 'antd';

const { Text } = Typography;

const index = () => {
  const orderbooks = [
    { price: 10000, amount: 100000, total: 100000 },
    { price: 10000, amount: 100000, total: 100000 },
    { price: 10000, amount: 100000, total: 100000 },
    { price: 10000, amount: 100000, total: 100000 },
    { price: 10000, amount: 100000, total: 100000 },
    { price: 10000, amount: 100000, total: 100000 },
    { price: 10000, amount: 100000, total: 100000 },
    { price: 10000, amount: 100000, total: 100000 },
    { price: 10000, amount: 100000, total: 100000 },
    { price: 10000, amount: 100000, total: 100000 },
  ];
  return (
    <Card bordered={false} className='h-auto' bodyStyle={{ padding: 8 }}>
      <span className='grid grid-cols-3 py-1.5 px-2'>
        <Text type='secondary'>Price</Text>
        <Text type='secondary text-end'>Amount</Text>
        <Text type='secondary text-end'>Total</Text>
      </span>
      <ol className='list-none p-0 m-0 overflow-auto max-h-[400px] md:max-h-[400px]  lg:max-h-[1021px]'>
        {orderbooks.map((order, i) => (
          <li
            key={i}
            className='grid grid-cols-3 py-1.5 hover:cursor-pointer rounded-md px-2'
          >
            <Text>{FORMAT.numberWithCommas(order.price, 2)}</Text>
            <Text className='text-end'>
              {FORMAT.numberWithCommas(order.amount, 2)}
            </Text>
            <Text className='text-end'>
              {FORMAT.numberWithCommas(order.total, 2)}
            </Text>
          </li>
        ))}
      </ol>
    </Card>
  );
};

export default index;
