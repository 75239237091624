import React, { useState } from 'react';
import useWindowSize from '../../customHooks/useWindowSize';
import { useTranslation } from 'react-i18next';

import MarketTableSM from '../MarketTableSM';
import { Card, Space, Typography, Modal } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const Index = () => {
  const [windowSize] = useWindowSize();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Modal
        title={t('NAV.MARKET')}
        visible={visible}
        onCancel={() => setVisible(false)}
        bodyStyle={{ padding: 8 }}
        footer={false}
      >
        <MarketTableSM />
      </Modal>
      <Card bordered={false} bodyStyle={{ padding: 16, height: '100%' }}>
        <div className='grid sm:grid-flow-col  sm:grid-cols-6 grid-cols-3  justify-between'>
          <div className='flex justify-end items-center'>
            {windowSize.width < 1024 ? (
              <Space align='baseline' onClick={() => setVisible(true)}>
                <Space size={0} align='baseline'>
                  <Title level={4} className='w-fit'>
                    BTC
                  </Title>
                  <Text type='secondary'>/USDT</Text>
                </Space>
                <DownOutlined />
              </Space>
            ) : (
              <Space size={0} align='baseline'>
                <Title level={4} className='w-fit'>
                  BTC
                </Title>
                <Text type='secondary'>/USDT</Text>
              </Space>
            )}
          </div>
          <Space direction='vertical' className='text-end py-2'>
            <Text type='secondary' className='whitespace-nowrap'>
              Last Price
            </Text>
            <Text strong>1000.00</Text>
          </Space>
          <Space direction='vertical' className='text-end py-2'>
            <Text type='secondary' className='whitespace-nowrap'>
              24H Change
            </Text>
            <Text
              strong
              className={78 !== 0 && 78 > 0 ? 'text-bids' : 'text-asks'}
            >
              78%
            </Text>
          </Space>
          <Space direction='vertical' className='text-end py-2'>
            <Text type='secondary' className='whitespace-nowrap'>
              24H Hight
            </Text>
            <Text strong>1000.00</Text>
          </Space>
          <Space direction='vertical' className='text-end py-2'>
            <Text type='secondary' className='whitespace-nowrap'>
              24H Low
            </Text>
            <Text strong>1000.00</Text>
          </Space>
          <Space direction='vertical' className='text-end py-2'>
            <Text type='secondary' className='whitespace-nowrap'>
              24H Volume
            </Text>
            <Text strong>1000.00</Text>
          </Space>
        </div>
      </Card>
    </>
  );
};

export default Index;
