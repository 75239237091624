import styled from 'styled-components'
import { Col, Row } from 'antd'
import ExternalLinkIcon from '../../Icon/ExternalLinkIcon'
import dotBg from '../../../assets/dot-bg.png';
import TransferIcon from '../../Icon/TransferIcon'
import ReduceIcon from '../../Icon/ReduceIcon'


const InformationBox = () => {
    const items = [
        {
            title: 'Help provide feedback',
            description: 'We are constantly looking for ways to improve your trading experience on dYdX! Please consider taking our 10 minute user survey.',
            externalLinkText: 'Take me there →',
            externalLink: 'https://www.google.com/',
            learnMoreLink: ''
        },
        {
            title: 'Trade for free',
            description: 'Enjoy no trading fees on your first $100,000 in volume every month!',
            externalLinkText: 'View fees →',
            externalLink: 'https://www.google.com/',
            learnMoreLink: 'https://www.google.com/',
            icon: <TransferIcon />
        },
        {
            title: 'Reduce-Only live',
            description: 'Reduce-Only is now live for all order types! Execution must be FOK or IOC to enable Reduce-Only.',
            externalLinkText: 'Trade →',
            externalLink: 'https://www.google.com/',
            learnMoreLink: 'https://www.google.com/',
            icon: <ReduceIcon />
        },
    ]

    return (
        <Wrapper>

            <Row gutter={[36, 12]}>
                {
                    items.map((item, index) => (
                        <Col key={index} xs={24} md={12} lg={8}>
                            <BoxWrapper>
                                <OverlayImage src={dotBg} />
                                <TitleWrapper>
                                    <Title className='information-box-title'>
                                        {item.title}
                                    </Title>
                                    {
                                        item?.icon && (
                                            <>
                                                {item.icon}
                                            </>
                                        )
                                    }
                                </TitleWrapper>

                                <Description>
                                    {item.description}
                                </Description>

                                <FooterWrapper>
                                    <ExternalLink onClick={() => window.open(item?.externalLink, '_blank')}>
                                        {item.externalLinkText}
                                    </ExternalLink>

                                    {
                                        item?.learnMoreLink && (
                                            <LearnMore onClick={() => window.open(item?.learnMoreLink, '_blank')}>
                                                <span>
                                                    Learn more
                                                </span>
                                                <ExternalLinkIcon />
                                            </LearnMore>
                                        )
                                    }
                                </FooterWrapper>
                            </BoxWrapper>
                        </Col>
                    ))
                }


            </Row>

        </Wrapper>
    )
}

export default InformationBox

const Wrapper = styled.div`

`

const BoxWrapper = styled.div`
  border: 1px solid #2D2D3D;
  border-radius: 12px;
  padding: 24px;
  position: relative;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 2;
  position: relative;
`

const Description = styled.div`
  font-weight: 500;
  font-size: 13px;
  color: #6F6E84;
  margin-bottom: 14px;
  height: 76px;
  z-index: 2;
  position: relative;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 16px;
  z-index: 2;
  position: relative;
`

const ExternalLink = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #5973FE;
  cursor: pointer;
  
  &:hover {
    font-size: 13px;
  }
`

const LearnMore = styled.div`
  font-weight: 500;
  font-size: 13px;
  color: #6F6E84;
  cursor: pointer;
  span {
    margin-left: 4px;
  }
`

const OverlayImage = styled.img`
    position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  top: 0;
  left: 0;
  z-index: 1;
`

const TitleWrapper = styled.div`
    display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`