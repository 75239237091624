import React from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../../customHooks/useWindowSize';
import _network from '../../mock/network.json';

import Banner from '../../components/Banner';
import {
  Card,
  Row,
  Col,
  Typography,
  Avatar,
  Input,
  Table,
  Tabs,
  Button,
  Space,
  Select,
  Tag,
} from 'antd';
import {
  StarOutlined,
  StarFilled,
  WalletOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import fmtIcon from '../../assets/FTM.png';
import ethIcon from '../../assets/ETH.png';
import bscIcon from '../../assets/BSC.png';

import { FORMAT } from '../../helper';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const Index = () => {
  const navigate = useNavigate();
  const [windowSize] = useWindowSize();

  const networks = [
    { title: 'All Network', icon: '', tag: '' },
    { title: 'FTM', icon: fmtIcon, tag: 'new' },
    { title: 'Ethereum', icon: ethIcon, tag: '' },
    { title: 'BSC', icon: bscIcon, tag: '' },
    { title: 'Polygon', icon: '', tag: '' },
    { title: 'Avalanche', icon: '', tag: '' },
    { title: 'Arbitrum', icon: '', tag: '' },
    { title: 'Aurora', icon: '', tag: 'beta' },
    { title: 'Rei', icon: '', tag: 'beta' },
  ];

  const tabs = [
    { title: 'All token' },
    { title: 'Imported' },
    { title: 'GameFi' },
    { title: 'DeFi' },
    { title: 'Yield Farming' },
    { title: 'Mems' },
    { title: 'NFT' },
    { title: 'Synthetic Assets' },
  ];

  const OperationsSlot = {
    left: (
      <Space className='mr-4'>
        <Button icon={<StarFilled className='text-yellow-400' />}>
          Favorites
        </Button>{' '}
        <Button icon={<WalletOutlined />}>My Wallet</Button>
      </Space>
    ),
    right: (
      <Select bordered={false} defaultValue='A-Z'>
        <Option value='A-Z'>A-Z</Option>
        <Option value='price'>Price</Option>
      </Select>
    ),
  };

  const columns = [
    {
      title: 'Asset',
      dataIndex: 'token',
      key: 'token',
      render: (text, record) => (
        <Space>
          <Button
            shape='circle'
            type='text'
            icon={
              <StarOutlined />
            }
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <div className='border-2 border-solid rounded-full border-lime-400 p-1'>
            <Avatar
              src={`https://cdn.arken.finance/token/aurora/${text.address}.png`}
            />
          </div>

          <Space direction='vertical' size={0}>
            <Text strong>{text.chain}/USDT</Text>
            <Text type='secondary' className='text-xs'>
              {text.chain}
            </Text>
          </Space>
        </Space>
      ),
    },
    {
      title: 'USD Value',
      dataIndex: 'volumeUSD',
      key: 'volumeUSD',
      align: 'right',
      render: (text) => <span>${FORMAT.numberWithCommas(text, 2)}</span>,
    },
    {
      title: '24H Change',
      dataIndex: 'change24h',
      key: 'change24h',
      align: 'right',
      render: (text) => (
        <Text className={text !== 0 && text > 0 ? 'text-bids' : 'text-asks'}>
          {FORMAT.numberWithCommas(text, 2)}%
        </Text>
      ),
    },
    {
      title: '24H Volume',
      dataIndex: 'close24h',
      key: 'close24h',
      align: 'right',
      render: (text) => <span>${FORMAT.numberWithCommas(text, 2)}</span>,
    },
    {
      title: '24H Hight / 24H Low',
      dataIndex: 'high24h',
      key: 'high24h',
      align: 'right',
      render: (text, record) => (
        <span>
          <Text className='whitespace-nowrap'>
            ${FORMAT.numberWithCommas(text, 2)} / ${FORMAT.numberWithCommas(record.low24h, 2)}
          </Text>
        </span>
      ),
    },
  ];

  const columnsSm = [
    {
      title: 'Assets / 24H Vol',
      dataIndex: 'token',
      key: 'token',
      render: (text, record) => (
        <Space>
          <Button
            type='text'
            shape='circle'
            icon={<StarOutlined />}
            onClick={(e) => e.stopPropagation()}
          />
          <div className='border-2 border-solid rounded-full border-lime-400 p-1'>
            <Avatar
              size='small'
              src={`https://cdn.arken.finance/token/aurora/${text.address}.png`}
            />
          </div>
          <Space direction='vertical' size={0}>
            <Text strong>{text.chain}/USDT</Text>
            <Text type='secondary' className='text-xs'>
              Vol. ${FORMAT.numberWithCommas(record.low24h, 2)}
            </Text>
          </Space>
        </Space>
      ),
    },
    {
      title: 'Price / %',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      render: (text, record) => (
        <Space direction='vertical'>
          <Text>{FORMAT.numberWithCommas(text, 2)}</Text>
          <Text
            type={
              record.change24h > 0
                ? 'success'
                : record.change24h < 0
                ? 'danger'
                : 'secondary'
            }
          >
            {record.change24h > 0 && '+'}
            {FORMAT.numberWithCommas(record.change24h, 2)}%
          </Text>
        </Space>
      ),
    },
  ];

  return (
    <>
      {/*<Banner />*/}
      <div className='container mx-auto p-4 sm:p-0'>
        <Title level={2} className='mt-10'>
          Top Crypto tokens
        </Title>
        <Input
          addonBefore={
            windowSize.width < 640 && (
              <Select defaultValue='All Network' size='large'>
                {networks.map((n, i) => (
                  <Option key={i} value={n.title}>
                    <Space>
                      {n.icon && <Avatar src={n.icon} size='small' />}
                      {n.title}
                    </Space>
                  </Option>
                ))}
              </Select>
            )
          }
          prefix={<SearchOutlined />}
          placeholder='Type bitcoin or address(0x...)'
          size='large'
        />
        {windowSize.width > 640 && (
          <Row gutter={[8, 8]} className='mt-4'>
            {networks.map((net, i) => (
              <Col key={i}>
                <Button size='large'>
                  <Space align='center'>
                    {net.icon && (
                      <Avatar src={net.icon} className='w-6 h-6 md-4' />
                    )}
                    {net.title}
                    {net.tag && (
                      <Tag color='magenta' className='capitalize'>
                        {net.tag}
                      </Tag>
                    )}
                  </Space>
                </Button>
              </Col>
            ))}
          </Row>
        )}
      </div>
      <div className='ant-card rounded-none secondary-background'>
        <div className='container mx-auto mt-8'>
          <Card bordered={false} className='secondary-background'>
            {windowSize.width < 640 && (
              <Row justify='space-between' className='mb-4'>
                <Col>{OperationsSlot.left}</Col>
                <Col>{OperationsSlot.right}</Col>
              </Row>
            )}
            <Tabs tabBarExtraContent={windowSize.width > 640 && OperationsSlot}>
              {tabs.map((tab) => (
                <TabPane key={tab.title} tab={tab.title}>
                  <Table
                    columns={windowSize.width < 640 ? columnsSm : columns}
                    dataSource={_network.slice(0, 10)}
                    pagination={false}
                    scroll={{ x: true }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          navigate(
                            `/trade/${record.token.chain}/${record.token.address}`
                          );
                        }, // click row
                      };
                    }}
                  />
                </TabPane>
              ))}
            </Tabs>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Index;
