import Icon from '@ant-design/icons'

const PositionsIcon = (props) => {
    const { color = '#6F6E84' } = props

    return (
        <Icon className='anticon' viewBox='0 0 12 12'>
            <path d="M5.96154 0.00145211C5.91005 0.00552709 5.85967 0.0176015 5.81249 0.0371717L0.274033 2.32322C0.192259 2.357 0.122819 2.41212 0.0741669 2.48188C0.0255144 2.55164 -0.000252747 2.63302 1.86888e-06 2.71614V9.28854C0.000672711 9.37089 0.0268735 9.45132 0.0754704 9.52021C0.124067 9.5891 0.193003 9.64353 0.274033 9.67699L5.81249 11.963C5.87152 11.9874 5.9354 12 6 12C6.0646 12 6.12848 11.9874 6.18751 11.963L11.726 9.67699C11.807 9.64353 11.8759 9.5891 11.9245 9.52021C11.9731 9.45132 11.9993 9.37089 12 9.28854V2.71614C12.0003 2.63302 11.9745 2.55164 11.9258 2.48188C11.8772 2.41212 11.8077 2.357 11.726 2.32322L6.18751 0.0371717C6.11662 0.00780917 6.0389 -0.00447615 5.96154 0.00145211ZM6 0.894441L10.4038 2.71614L6 4.53338L1.59616 2.71614L6 0.894441ZM0.923079 3.37249L5.53846 5.27902V10.9138L0.923079 9.00724V3.37249ZM11.0769 3.37249V9.00724L6.46154 10.9138V5.27902L11.0769 3.37249Z" fill={color} />
        </Icon>
    )
}

export default PositionsIcon