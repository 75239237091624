import React from 'react';
import _network from '../../mock/network.json';
import { FORMAT } from '../../helper';

import {
  Card,
  Typography,
  Space,
  Row,
  Col,
  Avatar,
  Tabs,
  Select,
  Input,
} from 'antd';
import { StarOutlined, StarFilled, WalletOutlined } from '@ant-design/icons';
import { MarketIcon } from '../CostomIcons';

const { Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const index = () => {
  const menu = [
    {
      key: '1',
      label: 'Favorites',
      icon: <StarFilled className='text-yellow-400' />,
    },
    {
      key: '2',
      label: 'My Wallet',
      icon: <WalletOutlined />,
    },
    {
      key: '3',
      label: 'Market',
      icon: <MarketIcon />,
    },
  ];

  const tabs = [
    { title: 'All token' },
    { title: 'Imported' },
    { title: 'GameFi' },
    { title: 'DeFi' },
    { title: 'Yield Farming' },
    { title: 'Mems' },
    { title: 'NFT' },
    { title: 'Synthetic Assets' },
  ];

  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: 8 }}
      headStyle={{ padding: '0px 8px' }}
      className='h-full'
      title={<Input.Search placeholder='Search' />}
      extra={
        <Select className='w-26' defaultValue='Favorites' bordered={false}>
          {menu.map((men, i) => (
            <Option key={men.key} value={men.label}>
              <Space>
                {men.icon && men.icon}
                {men.label}
              </Space>
            </Option>
          ))}
        </Select>
      }
    >
      <Tabs>
        {tabs.map((tab) => (
          <TabPane tab={tab.title} key={tab.title}>
            <ol className='list-none p-0 overflow-auto mb-0 h-[573px]'>
              {_network.map((net, i) => (
                <li
                  key={i}
                  className='hover:cursor-pointer px-3 py-2 rounded-sm ant-tab-cell-row-hover'
                >
                  <Row justify='space-between' className='w-full'>
                    <Col>
                      <Space align='center'>
                        <StarOutlined />
                        <Space>
                          <div className='border-w-1 aspect-square border-solid border-blue-400 rounded-full'>
                            <Avatar className='w-5 h-5 m-1' />
                          </div>
                          <Space direction='vertical' size={0}>
                            <Text strong>{net.token.chain}</Text>
                            <Space size={2}>
                              <Text type='secondary'>Vol.</Text>
                              <Text>${FORMAT.numberWithCommas(net.volumeUSD, 2)}</Text>
                            </Space>
                          </Space>
                        </Space>
                      </Space>
                    </Col>
                    <Col>
                      <div className='flex justify-between flex-col text-end w-full'>
                        <Text>{FORMAT.numberWithCommas(net.price, 2)}</Text>
                        <Text
                          className={
                            net.change24h !== 0 && net.change24h > 0
                              ? 'text-bids'
                              : 'text-asks'
                          }
                        >
                          {FORMAT.numberWithCommas(net.change24h, 2)}%
                        </Text>
                      </div>
                    </Col>
                  </Row>
                </li>
              ))}
            </ol>
          </TabPane>
        ))}
      </Tabs>
    </Card>
  );
};

export default index;
