import styled from 'styled-components'
import {
    CaretUpOutlined,
} from '@ant-design/icons';
import ExternalLinkIcon from '../../Icon/ExternalLinkIcon'
import DotBg from '../../../assets/dot-png-large.png'

const PortfolioValue = () => {

    return (
        <GridWrapper>
            <PortValue>
                <Title>
                    Portfolio Value
                </Title>

                <Value className='portfolio-value'>
                    $0.00
                </Value>

                <PercentageValue>
                    <CaretUpOutlined />
                    $0.00 (0.00%)
                    <PastWeek>
                        Past Week
                    </PastWeek>
                </PercentageValue>
            </PortValue>
            <Margin>
                <Title>
                    Margin Usage
                </Title>

                <EmptyValue>
                    -
                </EmptyValue>
            </Margin>
            <Free>
                <Title>
                    Free Collateral
                </Title>

                <EmptyValue>
                    -
                </EmptyValue>
            </Free>
            <Leverage>
                <Title>
                    Leverage
                </Title>

                <EmptyValue>
                    -
                </EmptyValue>
            </Leverage>
            <Buying>
                <Title>
                    Buying Power
                </Title>

                <EmptyValue>
                    -
                </EmptyValue>
            </Buying>
            <Graph>
                <OverlayImage src={DotBg} />
                <GraphContent className='graph-content'>
                    Perpetuals are not available to people or companies who are residents of, or are located, incorporated or have a registered agent in, the United States or a restricted territory. More details can be found in our Terms of Use
                    <ExternalLinkIcon />
                </GraphContent>
            </Graph>
        </GridWrapper>
    )
}

export default PortfolioValue

const GridWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 4fr;
  grid-template-rows: 1fr ;
  gap: 0 0;

  grid-template-areas: 
    "Portfolio-Value Portfolio-Value Graph"
    "Margin Free Graph"
    "Leverage Buying Graph";
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr ;
    grid-template-areas: 
    "Portfolio-Value Portfolio-Value"
    "Margin Free"
    "Leverage Buying"
    "Graph Graph";
  }
`

const PortValue = styled.div`
  grid-area: Portfolio-Value;
  padding: 24px;

  border-right: 1px solid #2D2D3D;
  border-bottom: 1px solid #2D2D3D;
`

const Margin = styled.div`
  grid-area: Margin;
  padding: 24px;

  border-right: 1px solid #2D2D3D;
  border-bottom: 1px solid #2D2D3D;
`

const Free = styled.div`
  grid-area: Free;
  padding: 24px;

  border-right: 1px solid #2D2D3D;
  border-bottom: 1px solid #2D2D3D;
`

const Leverage = styled.div`
  grid-area: Leverage;
  padding: 24px;
  
  border-right: 1px solid #2D2D3D;
  border-bottom: 1px solid #2D2D3D;
`

const Buying = styled.div`
  grid-area: Buying;
  padding: 24px;

  border-right: 1px solid #2D2D3D;
  border-bottom: 1px solid #2D2D3D;
`

const Graph = styled.div`
  position: relative;
  grid-area: Graph;
  padding: 24px;
  border-bottom: 1px solid #2D2D3D;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Title = styled.div`
    color: #6F6E84;
`

const Value = styled.div`
  font-weight: 500;
  font-size: 24px;
  margin-top: 20px;
`

const PercentageValue = styled.div`
    font-weight: 500;
    font-size: 13px;
    color: #3FB68B;
  
`

const PastWeek = styled.span`
  color: #6F6E84;
  margin-left: 4px;
`

const EmptyValue = styled.div`
    font-weight: 500;
    font-size: 20px;
    color: #C3C2D4;
  margin-top: 6px;
`

const GraphContent = styled.div`
  border-radius: 8px;
  padding: 18px;
  max-width: 268px;
  position: relative;
  z-index: 2;
  text-align: center;
`

const OverlayImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: contain;
  object-position: top;
`