import React from 'react';
import { FORMAT } from '../../helper';

import { Typography, Row, Col, Card, Avatar } from 'antd';
import btcIcon from '../../assets/BTC.png';

const { Title, Text } = Typography;

const index = () => {
  const getRandomArbitrary = (min, max) => {
    return Math.random() * (max - min) + min;
  };
  const cards = [
    {
      symbol: 'BTC',
      icon: btcIcon,
      price: getRandomArbitrary(100, 100000),
      changed: getRandomArbitrary(0, 1),
      volume: getRandomArbitrary(100, 100000),
    },
    {
      symbol: 'ETH',
      icon: btcIcon,
      price: getRandomArbitrary(100, 100000),
      changed: getRandomArbitrary(0, 1),
      volume: getRandomArbitrary(100, 100000),
    },
    {
      symbol: 'AAVE',
      icon: btcIcon,
      price: getRandomArbitrary(100, 100000),
      changed: getRandomArbitrary(0, 1),
      volume: getRandomArbitrary(100, 100000),
    },
    {
      symbol: 'BCH',
      icon: btcIcon,
      price: getRandomArbitrary(100, 100000),
      changed: getRandomArbitrary(0, 1),
      volume: getRandomArbitrary(100, 100000),
    },
  ];

  return (
    <div className='ant-layout h-auto sm:h-96 w-screen relative left-0 right-0 flex justify-center items-center p-4'>
      <div className='container mt-16 sm:mx-auto relative '>
        <Title className='text-center'>This is Title</Title>
        <Row gutter={[8, 8]}>
          {cards.map((c, i) => (
            <Col key={i} xs={24} sm={12} md={6} lg={6}>
              <Card bordered={false}>
                <Row>
                  <Col span={24}>
                    <Row justify='space-between'>
                      <Col>{c.symbol}/USDT</Col>
                      <Col>
                        <Avatar src={btcIcon} />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[8, 8]} justify='space-between'>
                      <Col span={24}>
                        <Title level={3}>{FORMAT.numberWithCommas(c.price, 2)}</Title>
                      </Col>
                      <Col>
                        <Text type='success' strong>
                          + {FORMAT.numberWithCommas(c.changed * 100, 2)}%
                        </Text>
                      </Col>
                      <Col>
                        <Text type='secondary'>
                          {FORMAT.numberWithCommas(c.volume, 2)}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default index;
