import Icon from '@ant-design/icons'

const ReduceIcon = (props) => {
    const { color = '#C3C2D4' } = props

    return (
        <Icon style={{ fontSize: '18px' }} className='anticon' viewBox='0 0 22 22'>
            <g clipPath="url(#clip0_84_1193)">
                <path d="M5.49121 13.2265H9.87402M9.87402 13.2265V17.6094M9.87402 13.2265L3.2998 19.8008M17.6084 9.875H13.2255M13.2255 9.875V5.49219M13.2255 9.875L16.5127 6.58789L19.7998 3.30078" stroke={color} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_84_1193">
                    <rect width="22" height="22" fill="white"/>
                </clipPath>
            </defs>
        </Icon>
    )
}

export default ReduceIcon