import styled from 'styled-components'
import { Avatar, Button, Space, Table } from 'antd'

import { FORMAT } from '../../helper'
import _network from '../../../src/mock/earn.json'


const Earn = () => {

    const columns = [
        {
            title: 'Market',
            dataIndex: 'token',
            key: 'market',
            render: (text) => (
                <Space>
                    <Avatar
                        src={`https://cdn.arken.finance/token/aurora/${text?.address}.png`}
                    />

                    <Space direction='vertical' size={0}>
                        <TokenText className='market-token-text'>{text?.symbol}</TokenText>
                        <NameText>
                            {text?.name}
                        </NameText>
                    </Space>
                </Space>
            )
        },
        {
            title: 'Metamask Balance',
            dataIndex: 'metamaskBalance',
            key: 'metamaskBalance',
            align: 'center',
            render: (text) => <span>{FORMAT.numberWithCommas(text, 2)}</span>,
        },
        {
            title: 'Total Stake',
            dataIndex: 'totalStake',
            key: 'totalStake',
            align: 'right',
            render: (text) => <span>{FORMAT.numberWithCommas(text, 2)}</span>,
        },
        {
            title: 'Total Reward',
            dataIndex: 'totalReward',
            key: 'totalReward',
            align: 'right',
            render: (text) => <RewardWrapper>{FORMAT.numberWithCommas(text, 2)}</RewardWrapper>,
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: () => (
                <ActionWrapper>
                    <Button type='link'>
                        Deposit
                    </Button>
                </ActionWrapper>
            )
        }
    ]


    return (
        <div>
            <MarketHeaderWrapper>
                <MarketTitle className='market-title'>
                    Earn
                </MarketTitle>
            </MarketHeaderWrapper>

            <Description>
                Simple earn by only deposit token to CRESTS wallet
            </Description>

            <Spacer />

            {/* Asset Table */}
            <Table
                columns={columns}
                dataSource={_network.slice(0, 10)}
                pagination={false}
                scroll={{ x: true }}
            />

        </div>
    )
}

export default Earn

const MarketHeaderWrapper = styled.div`
    display: flex;
  justify-content: space-between;
  align-items: center;
`

const MarketTitle = styled.div`
  font-weight: 500;
  font-size: 19px;
  color: #F7F7F7;
`

const Description = styled.div`
  font-weight: 500;
  font-size: 13px;
  color: #6F6E84;
`

const Spacer = styled.div`
  height: 36px;
`

const TokenText = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #C3C2D4;
`

const NameText = styled.div`
  font-weight: 400;
  font-size: 11px;
  color: #6F6E84;
`

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const RewardWrapper = styled.span`
  color: #3FB68B;
  font-weight: bold;
`