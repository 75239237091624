import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from 'antd';
import { FrownOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const Index = () => {
  const navigate = useNavigate();
  return (
    <div className='h-screen flex justify-center items-center flex-col'>
      <FrownOutlined className='mb-2' style={{ fontSize: '10em' }} />
      <Title className='mb-0'>404</Title>
      <Text type='secondary'>Page Not Found</Text>
      <Button className='mt-4' type='primary' onClick={() => navigate(-1)}>
        Go back
      </Button>
    </div>
  );
};

export default Index;
