import { createReduxAsyncTask } from '@makejack4/redux-saga-toolkit'
import { put } from '@redux-saga/core/effects'

import { MODULE_NAME } from './types'

export const changeThemeTask = createReduxAsyncTask({
    moduleName: MODULE_NAME,
    name: 'changeTheme',
    defaultPayload : 'dark', // sample value
    defaultData: { ok: true },
    saga: ({ actions }) =>
        function* ({ payload }) {
            try {
                const theme = payload

                localStorage.setItem('theme', theme);

                yield put(
                    actions.mergeValue({
                        theme
                    }),
                )

                yield put(actions.success({ ok: true }))
            } catch (error) {
                yield put(actions.failure(error))
            }
        },
})

export const changeLanguageTask = createReduxAsyncTask({
    moduleName: MODULE_NAME,
    name: 'changeLanguage',
    defaultPayload : 'en', // sample value
    defaultData: { ok: true },
    saga: ({ actions }) =>
        function* ({ payload }) {
            try {
                const lang = payload

                yield put(
                    actions.mergeValue({
                        lang
                    }),
                )

                yield put(actions.success({ ok: true }))
            } catch (error) {
                yield put(actions.failure(error))
            }
        },
})
