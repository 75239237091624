import React from 'react';
import { Layout, Divider } from 'antd';

const { Footer } = Layout;
const Index = () => {
  return (
    <>
      <Footer className='text-center bg-black text-white'>
        <Divider className='border-white mt-0' />© 2022 Crests All rights
        reserved Version. 0.1
      </Footer>
    </>
  );
};

export default React.memo(Index);
