import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMetaMask } from 'metamask-react';

import {
  Row,
  Col,
  Button,
  Modal,
  List,
  Avatar,
  Select,
  Card,
  Popover,
  Switch,
  Divider,
  Menu,
  Typography,
  Space,
} from 'antd';
import {
  MenuOutlined,
  WalletOutlined,
  StockOutlined,
  ExclamationCircleOutlined,
  FolderOpenOutlined
} from '@ant-design/icons';
import LogoDark from '../../assets/chests_logo.png';
import LogoSm from '../../assets/chests_logo.png';
import { MarketIcon, MoonIcon, SunIcon } from '../CostomIcons';
import metamarkImg from '../../assets/metamark.svg';
import styled from 'styled-components'
import { hideText } from '../../helper/hide-text'
import { langSelector, themeSelector } from '../../modules/common/selector';
import { changeLanguageTask, changeThemeTask } from '../../modules/common/tasks';

const { Option } = Select;

const Index = () => {
  const { status, connect, account, chainId } = useMetaMask();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = useSelector(langSelector);
  const theme = useSelector(themeSelector);

  const [visible, setVisible] = useState(false);
  const [visibleAccount, setVisibleAccount] = useState(false);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState('');

  const { onRequest : changeTheme } = changeThemeTask.useTask()
  const { onRequest : changeLanguage } = changeLanguageTask.useTask()

  useEffect(() => {
    if (visibleMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [visibleMenu]);

  useEffect(() => {
    let splitSlash = location?.pathname?.split('/')[1];
    setSelectedKeys(splitSlash);
  }, [location]);

  const onConnectMetamark = () => {
    // if (status === 'initializing')

    if (status === 'unavailable') {
      Modal.warning({ content: 'MetaMask not available' });
    }

    if (status === 'notConnected') {
      connect();
    }

    if (status === 'connected') setVisible(false);
  };

  const data = [
    {
      title: 'Metamask',
    },
    // {
    //   title: 'BitkubNext',
    // },
  ];

  const _nav = [
    {
      label: t('NAV.MARKET'),
      key: 'market',
      // icon: <MailOutlined />,
    },
    {
      label: t('NAV.TRADE'),
      key: 'trade',
      // icon: <MailOutlined />,
    },
    {
      key: 'portfolio',
      label: t('NAV.PORTFOLIO'),
      // icon: <FolderOpenOutlined />,
    },
    {
      key: 'rewards',
      label: 'Rewards',
      disabled: true
    },
    {
      key: 'competitions',
      label: 'Competitions',
      disabled: true
    },
    {
      key: 'governance',
      label: 'Governance',
      disabled: true
    },
  ];

  return (
    <>
      <Modal
        footer={false}
        visible={visible}
        title='Connect to a wallet'
        onCancel={() => setVisible(false)}
        className='w-80'
      >
        <List
          itemLayout='horizontal'
          className='hover:cursor-pointer dark:hover:bg-[#252f42] hover:bg-[#fafafa] rounded-md px-4'
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              onClick={() => {
                status !== 'unavailable' && onConnectMetamark();
              }}
            >
              <List.Item.Meta
                title={
                  <Row
                    justify='space-between'
                    align='middle'
                    className='h-[32px]'
                  >
                    <Col>
                      <Typography.Text>{item.title}</Typography.Text>{' '}
                    </Col>
                    <Col>
                      <Space>
                        <Avatar src={metamarkImg} />
                        {status === 'unavailable' && (
                          <Popover content='Not available'>
                            <ExclamationCircleOutlined className='text-red-500' />
                          </Popover>
                        )}
                      </Space>
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )}
        />
      </Modal>

      <Modal
        title='Account Info'
        visible={visibleAccount}
        onCancel={() => setVisibleAccount(false)}
        footer={false}
      >
        <Row justify='space-between'>
          <Col>
            <Typography.Text type='secondary'>My Wallet</Typography.Text>
          </Col>
          <Col>
            <Typography.Text copyable className='whitespace-nowrap'>
              {account}
            </Typography.Text>
          </Col>
        </Row>
      </Modal>
      <StyledCard
          background={theme === 'light' ? '#1033A7' : '#00112A'}
        className='rounded-none h-16 z-10 sticky top-0 ms:px-8 px-4 border-t-0 border-x-0 border-b'
        bodyStyle={{ padding: 0 }}
      >
        <Row justify='space-between h-16'>
          <Col className='h-16 flex items-center'>
            <img
              src={LogoDark}
              className='hidden sm:block w-auto h-16 py-2'
              alt='Crests'
            />
            <img
              src={LogoSm}
              className='block sm:hidden w-auto h-16 py-2'
              alt='Crests'
            />

          </Col>
          <Col className='flex justify-center items-center'>
            <ol className='my-0 mx-6 p-0 list-none  gap-4 h-8 md:flex hidden items-center '>
              {_nav.map((nav, i) => (
                  <li key={i}>
                    {
                      nav?.disabled ? (
                          <span style={{ cursor: 'not-allowed', opacity: .5 }}>
                            {nav.label}
                          </span>
                      ) : (
                          <NavLink
                              to={nav.key}
                              className='font-medium'
                              style={({ isActive }) =>
                                  isActive
                                      ? { color: '#02AFFF' }
                                      : {
                                        color: theme === 'light' ? '#fff' : '#fff',
                                      }
                              }
                          >
                            {nav.label}
                          </NavLink>
                      )
                    }

                  </li>
              ))}
            </ol>
            <Row gutter={8}>
              <Col>
                {status === 'connected' ? (
                  <div
                    className='h-[32px] flex justify-center items-center border-1 border-solid border-primary rounded hover:cursor-pointer'
                    onClick={() => setVisibleAccount(true)}
                  >
                    {/*<Typography.Text ellipsis className='w-[78px] p-1'>*/}
                    {/*  {account}*/}
                    {/*</Typography.Text>*/}
                    <Typography.Text className='bg-primary text-white px-2 py-1 h-full'>
                      {hideText(account, 6)}
                    </Typography.Text>
                  </div>
                ) : (
                  <Button
                    type='primary'
                    className='bg-gradient'
                    icon={<WalletOutlined />}
                    onClick={() => setVisible(!visible)}
                  >
                    Connect
                  </Button>
                )}
              </Col>
              <Col className='md:block hidden'>
                <Select
                  value={lang}
                  bordered={false}
                  onSelect={(e) => changeLanguage(e)}
                >
                  <Option value='en'>EN</Option>
                  <Option value='th'>TH</Option>
                </Select>
              </Col>
              <Col className='md:block hidden'>
                <Button
                  type='text'
                  shape='circle'
                  icon={
                    theme === 'light' ? (
                      <SunIcon className='text-primary' />
                    ) : (
                      <MoonIcon className='text-primary' />
                    )
                  }
                  onClick={() =>
                    changeTheme(theme === 'light' ? 'dark' : 'light')
                  }
                />
              </Col>
              <Col className='block md:hidden'>
                <Popover
                  arrowContent={false}
                  visible={visibleMenu}
                  onVisibleChange={() => setVisibleMenu(!visibleMenu)}
                  trigger='click'
                  content={
                    <div className='w-[200px]'>
                      <Menu
                        className='border-0'
                        selectedKeys={selectedKeys}
                        onClick={(e) => {
                          setVisibleMenu(false);
                          navigate(e.key);
                        }}
                        items={[
                          {
                            key: 'market',
                            label: t('NAV.MARKET'),
                            icon: <MarketIcon />,
                          },
                          {
                            key: 'trade',
                            label: t('NAV.TRADE'),
                            icon: <StockOutlined />,
                          },
                          {
                            key: 'portfolio',
                            label: t('NAV.PORTFOLIO'),
                            icon: <FolderOpenOutlined />,
                          },
                          {
                            key: 'rewards',
                            label: 'Rewards',
                            disabled: true
                          },
                          {
                            key: 'competitions',
                            label: 'Competitions',
                            disabled: true
                          },
                          {
                            key: 'governance',
                            label: 'Governance',
                            disabled: true
                          },
                        ]}
                      />
                      <Divider />
                      <Select
                        bordered={false}
                        value={lang}
                        className='pl-0 w-[200px]'
                        onSelect={(e) => changeLanguage(e)}
                        dropdownClassName='pl-0'
                      >
                        <Option value='en'>English</Option>
                        <Option value='th'>ไทย</Option>
                      </Select>
                      <div className='flex justify-between mt-4 px-3'>
                        Dark Theme
                        <Switch
                          checked={theme === 'light' ? false : true}
                          checkedChildren='On'
                          unCheckedChildren='Off'
                          onChange={() =>
                            changeTheme(theme === 'light' ? 'dark' : 'light')
                          }
                        />
                      </div>
                    </div>
                  }
                  placement='bottomRight'
                >
                  <Button
                    type='text'
                    shape='circle'
                    icon={<MenuOutlined />}
                    onClick={() => setVisibleMenu(!visibleMenu)}
                  />
                </Popover>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledCard>
    </>
  );
};

export default Index;

const StyledCard = styled(Card)`
  background: ${props => props.background} !important;
`
