import React from 'react';
import { useSelector } from 'react-redux';
import useWindowSize from '../../customHooks/useWindowSize';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import { themeSelector } from '../../modules/common/selector';

const Index = () => {
  const [windowSize] = useWindowSize();
  const theme = useSelector(themeSelector);

  return (
    <div style={{ height: windowSize.width < 1024 ? 450 : '100%' }}>
      <TradingViewWidget
        symbol='BTC/USDT'
        theme={theme === 'light' ? Themes.LIGHT : Themes.DARK}
        autosize
      />
    </div>
  );
};

export default Index;
