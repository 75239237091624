import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { MetaMaskProvider } from 'metamask-react';
import './i18n';
import { makeStore } from './store/make-store'

import { ThemeSwitcherProvider } from 'react-css-theme-switcher';

const themes = {
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
};

// set redux store
const store = makeStore()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MetaMaskProvider>
      <Provider store={store}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme='dark'>
          <App />
        </ThemeSwitcherProvider>
      </Provider>
    </MetaMaskProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
