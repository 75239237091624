import React, { useState } from 'react';
import useWindowSize from '../../customHooks/useWindowSize';

import {
  Card,
  Tabs,
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Typography,
  Space,
} from 'antd';

const { TabPane } = Tabs;
const { Text } = Typography;

const percents = [25, 50, 75, 100];

const Index = ({ title = 'Exchange' }) => {
  const [windowSize] = useWindowSize();

  const [order, setOrder] = useState('buy');
  const [orderType, setOrderType] = useState('limit');

  return (
    <Card
      className='h-auto'
      bordered={false}
      title={title}
      bodyStyle={{ padding: 8 }}
    >
      {windowSize.width > 1024 ? (
        <Tabs>
          <TabPane tab='Limit' key='limitt'>
            <Row gutter={16}>
              <Col span={12}>
                <LimitForm submitText='buy' />
              </Col>
              <Col span={12}>
                <LimitForm submitText='sell' />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab='Market' key='market'>
            <Row gutter={16}>
              <Col span={12}>
                <MarketForm submitText='buy' />
              </Col>
              <Col span={12}>
                <MarketForm submitText='sell' />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      ) : (
        <>
          <div className='mb-2'>
            <Row gutter={8}>
              <Col span={12}>
                <Button
                  block
                  type={order === 'buy' ? 'bids-outline' : 'text'}
                  onClick={() => setOrder('buy')}
                >
                  Buy
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  block
                  type={order === 'sell' ? 'asks-outline' : 'text'}
                  onClick={() => setOrder('sell')}
                >
                  Sell
                </Button>
              </Col>
            </Row>
            <Space className='pt-4'>
              <Button
                type={orderType === 'limit' ? 'link' : 'text'}
                className='p-0'
                onClick={() => setOrderType('limit')}
              >
                Limit
              </Button>
              <Button
                type={orderType === 'market' ? 'link' : 'text'}
                className='p-0'
                onClick={() => setOrderType('market')}
              >
                Market
              </Button>
            </Space>
          </div>
          {orderType === 'limit' ? (
            <LimitForm submitText={order} />
          ) : (
            <MarketForm submitText={order} />
          )}
        </>
      )}
    </Card>
  );
};

export default React.memo(Index);

const HeadFrom = () => (
  <Form.Item>
    <Row justify='space-between'>
      <Col>
        <Text type='secondary'>Available</Text>
      </Col>
      <Col>
        10000.00 <Text type='secondary'>USDT</Text>
      </Col>
    </Row>
  </Form.Item>
);

const ActionFrom = ({ submitText }) => (
  <Form.Item>
    <Button
      block
      type={submitText === 'buy' ? 'bids' : 'asks'}
      className='capitalize'
      danger={submitText === 'sell'}
    >
      {submitText || 'Submit'}
    </Button>
  </Form.Item>
);

const LimitForm = ({ submitText }) => (
  <Form>
    <HeadFrom />
    <Form.Item rules={[{}]}>
      <CustomInput prefix='Price' suffix='USDT' />
    </Form.Item>
    <Form.Item>
      <CustomInput prefix='Amount' suffix='BTC' />
    </Form.Item>
    <Form.Item>
      <Radio.Group size='small' className='mb-4'>
        {percents.map((per, i) => (
          <Radio.Button key={i} value={per}>
            {per}%
          </Radio.Button>
        ))}
      </Radio.Group>
      <CustomInput prefix='Total' suffix='USDT' />
    </Form.Item>
    <ActionFrom submitText={submitText} />
  </Form>
);

const MarketForm = ({ submitText }) => (
  <Form>
    <HeadFrom />
    <Form.Item>
      <CustomInput prefix='Amount' suffix='BTC' />
    </Form.Item>
    <ActionFrom submitText={submitText} />
  </Form>
);

const CustomInput = ({
  value,
  onChange,
  prefix,
  suffix,
  disabled = false,
  addonBefore,
}) => {
  const [number, setNumber] = useState(null);

  const triggerChange = (changeValue) => {
    onChange?.(changeValue);
  };

  const onNumberChange = (e) => {
    const newNumber = e.target.value;
    const reg = /^-?\d*((?:\.\d{0,8})?)?$/;

    if ((!isNaN(newNumber) && reg.test(newNumber)) || newNumber === '') {
      setNumber(newNumber);
      triggerChange(newNumber);
    }
  };

  return (
    <span>
      <Input
        type='text'
        className='ant-input-end'
        prefix={prefix}
        suffix={<Typography.Text type='secondary'>{suffix}</Typography.Text>}
        disabled={disabled}
        value={value || number}
        onChange={onNumberChange}
        addonBefore={addonBefore}
      />
    </span>
  );
};
