import Icon from '@ant-design/icons';

const SunSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='currentColor'>
    <path
      d='M9,1a.727.727,0,0,1,.727.727V3.182a.727.727,0,0,1-1.455,0V1.727A.727.727,0,0,1,9,1ZM4.636,9a4.364,4.364,0,1,1,1.278,3.086A4.363,4.363,0,0,1,4.636,9ZM9,6.091a2.909,2.909,0,1,0,2.057.852A2.909,2.909,0,0,0,9,6.091Zm.727,8.727a.727.727,0,0,0-1.455,0v1.455a.727.727,0,0,0,1.455,0ZM17,9a.727.727,0,0,1-.727.727H14.818a.727.727,0,0,1,0-1.455h1.455A.727.727,0,0,1,17,9ZM3.182,9.727a.727.727,0,0,0,0-1.455H1.727a.727.727,0,0,0,0,1.455ZM14.657,3.343a.727.727,0,0,1,0,1.028L13.628,5.4A.727.727,0,1,1,12.6,4.372l1.028-1.028a.727.727,0,0,1,1.028,0ZM5.4,13.628A.727.727,0,1,0,4.372,12.6L3.343,13.628a.727.727,0,1,0,1.028,1.028ZM3.343,3.343a.727.727,0,0,1,1.028,0L5.4,4.372A.727.727,0,1,1,4.372,5.4L3.343,4.372a.727.727,0,0,1,0-1.028ZM13.628,12.6A.727.727,0,0,0,12.6,13.628l1.028,1.028a.727.727,0,0,0,1.028-1.028Z'
      transform='translate(-1 -1)'
      fillRule='evenodd'
    />
  </svg>
);

const MarketSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='currentColor'>
    <path
      data-name='Path 2'
      d='M5.636,10.727H2.727A.687.687,0,0,0,2,11.455v5.818A.687.687,0,0,0,2.727,18H5.636a.687.687,0,0,0,.727-.727V11.455A.687.687,0,0,0,5.636,10.727ZM17.273,7.818H14.364a.687.687,0,0,0-.727.727v8.727a.687.687,0,0,0,.727.727h2.909A.687.687,0,0,0,18,17.273V8.545A.687.687,0,0,0,17.273,7.818ZM11.455,2H8.545a.687.687,0,0,0-.727.727V17.273A.687.687,0,0,0,8.545,18h2.909a.687.687,0,0,0,.727-.727V2.727A.687.687,0,0,0,11.455,2Z'
      transform='translate(-2 -2)'
    />
  </svg>
);

const SwapSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='currentColor'>
    <path
      id='Subtraction_1'
      data-name='Subtraction 1'
      d='M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM8.805,8.93l-.042,0a.437.437,0,0,0-.267.745l1.749,1.747.041.036a.435.435,0,0,0,.268.092h.027a.438.438,0,0,0,.282-.127l1.745-1.747.037-.041a.439.439,0,0,0-.037-.577l-.041-.036a.436.436,0,0,0-.269-.092h-.027a.435.435,0,0,0-.281.128l-1,1V5.438l0-.05A.437.437,0,0,0,10.552,5L10.5,5a.431.431,0,0,0-.275.143.436.436,0,0,0-.111.29v4.621l-1-1-.041-.036A.435.435,0,0,0,8.805,8.93ZM5.747,6.49v4.625l0,.051a.437.437,0,0,0,.434.385l.05,0a.438.438,0,0,0,.386-.434V6.493l1,1,.041.037a.444.444,0,0,0,.27.093l.039,0a.437.437,0,0,0,.267-.745L6.491,5.127l-.041-.036A.442.442,0,0,0,6.18,5H6.154a.435.435,0,0,0-.281.128L4.128,6.875l-.037.041a.435.435,0,0,0,.037.576l.041.037a.437.437,0,0,0,.266.091h.029a.438.438,0,0,0,.282-.127l1-1Z'
      transform='translate(0 0)'
    />
  </svg>
);

const MoonSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 16 16' fill='currentColor'>
    <path
      id='Path_3'
      data-name='Path 3'
      d='M6,.278a.768.768,0,0,1,.08.858A7.208,7.208,0,0,0,5.2,4.6a7.3,7.3,0,0,0,7.318,7.277,7.355,7.355,0,0,0,1.533-.16.787.787,0,0,1,.81.316.733.733,0,0,1-.031.893A8.321,8.321,0,1,1,5.124.06.752.752,0,0,1,6,.278Z'
      transform='translate(0 0)'
    />
    <path
      id='Path_4'
      data-name='Path 4'
      d='M8.795,3.148a.217.217,0,0,1,.412,0L9.594,4.31a1.736,1.736,0,0,0,1.1,1.1l1.162.387a.217.217,0,0,1,0,.412l-1.162.387a1.734,1.734,0,0,0-1.1,1.1L9.207,8.852a.217.217,0,0,1-.412,0L8.408,7.69a1.734,1.734,0,0,0-1.1-1.1L6.149,6.206a.217.217,0,0,1,0-.412l1.162-.387a1.734,1.734,0,0,0,1.1-1.1ZM11.864.1a.145.145,0,0,1,.274,0L12.4.873a1.155,1.155,0,0,0,.732.732l.774.258a.145.145,0,0,1,0,.274l-.774.258a1.157,1.157,0,0,0-.732.732l-.258.774a.145.145,0,0,1-.274,0l-.258-.774a1.156,1.156,0,0,0-.732-.732L10.1,2.137a.145.145,0,0,1,0-.274l.774-.258a1.154,1.154,0,0,0,.732-.732L11.864.1Z'
      transform='translate(2 0)'
    />
  </svg>
);

const SunIcon = (props) => <Icon component={SunSvg} {...props} />;
const MoonIcon = (props) => <Icon component={MoonSvg} {...props} />;
const MarketIcon = (props) => <Icon component={MarketSvg} {...props} />;
const SwapIcon = (props) => <Icon component={SwapSvg} {...props} />;

export { SunIcon, MoonIcon, MarketIcon, SwapIcon };
